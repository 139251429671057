// extracted by mini-css-extract-plugin
export var borderLine = "styles-module--borderLine--5BMGg";
export var btnDelete = "styles-module--btnDelete--NtHN2";
export var content = "styles-module--content--PTsDN";
export var date = "styles-module--date--4Q1mL";
export var deleteAll = "styles-module--deleteAll--jJmTl";
export var feature = "styles-module--feature--8BrLD";
export var main = "styles-module--main--YW5kv";
export var notificationContent = "styles-module--notificationContent--RG7rc";
export var notificationItem = "styles-module--notificationItem--5YlSD";
export var notificationTitle = "styles-module--notificationTitle--dRiuH";
export var pageTitle = "styles-module--pageTitle--Oz8q7";
export var pagination = "styles-module--pagination--ZQh7z";
export var read = "styles-module--read--1B3bF";
export var rightContent = "styles-module--rightContent--+iadc";
export var title = "styles-module--title--TcxFr";