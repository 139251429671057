import {Modal} from 'antd';

export default ({message, onOk, onCancel, okText = 'OK', cancelText = 'CANCEL', title = 'Confirm'}) => {
  Modal.confirm({
    title,
    content: message,
    okText,
    cancelText,
    onOk,
    onCancel,
  });
};
