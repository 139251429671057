import React, {useEffect, useState} from 'react';
import {get, put, remove} from '~utils/api';
import {formatDate, generateUrlParameters, objectEmpty} from '~utils/helper';
import {requestDeleteNotify} from '~constants/account';
import confirmation from '~utils/modals/confirmation';
import {Empty, Pagination} from 'antd';
import AvatarNotification from '~images/avatar-notification.png';

import * as styles from './styles.module.css';

const NotificationComponent = () => {
  const [notificationList, setNotificationList] = useState([]);
  const [pagination, setPagination] = useState({
    limit: 30,
    page: 1,
  });
  const [total, setTotal] = useState(0);

  const fetchData = async () => {
    try {
      const urlParams = generateUrlParameters({
        'pagination.limit': pagination.limit,
        'pagination.page': pagination.page,
        'sort.prop_name': 'CreatedDate',
        'sort.direction': 'Desc',
      });

      const res = await get(`/v1/notification/list?${urlParams}`);
      if (!objectEmpty(res)) {
        setNotificationList(res.items);
        setTotal(res.total);
      } else {
        setNotificationList([]);
      }
    } catch {}
  };

  useEffect(() => {
    fetchData();
  }, [pagination]);

  const onRead = async request => {
    await put(`/v1/notification`, request);
    fetchData();
  };

  const onDelete = async request => {
    await remove(`/v1/notification`, request);
    setTotal(0);
    fetchData();
  };

  const onDeleteAll = () => {
    confirmation({
      message: 'Bạn có muốn xóa tất cả thông báo?',
      onOk: () => onDelete({...requestDeleteNotify, isDeleteAll: true}),
    });
  };

  return (
    <div className={styles.notificationContent}>
      <div className={styles.pageTitle}>
        {total > 0 && (
          <div className={styles.deleteAll} onClick={() => onDeleteAll()}>
            Xóa tất cả
          </div>
        )}
      </div>
      <div id='notification-container'>
        {notificationList.length === 0 && <Empty description='Chưa có thông báo!' />}
        {notificationList.map((item, index) => (
          <div key={item.id}>
            <div className={styles.notificationItem}>
              <div className={styles.main}>
                <div>
                  <img src={AvatarNotification} alt='' srcSet='' width={40} height={40} />
                </div>
                <div>
                  <div className={styles.title}>{item.title}</div>
                  <div className={styles.date}>({formatDate(new Date(item.createdDate), 'LL')})</div>
                  <div className={styles.content}>{item.content}</div>
                </div>
              </div>
              <div className={styles.rightContent}>
                <div className={styles.feature}>
                  {!item.wasRead && (
                    <>
                      <span
                        className={styles.read}
                        onClick={() =>
                          onRead({
                            ids: [item.id],
                            isReadAll: false,
                          })
                        }
                      >
                        Đã đọc
                      </span>
                      <span className={styles.separate}>|</span>
                    </>
                  )}
                  <span
                    className={styles.btnDelete}
                    onClick={() =>
                      onDelete({
                        ...requestDeleteNotify,
                        ids: [item.id],
                      })
                    }
                  >
                    Xóa
                  </span>
                </div>
              </div>
            </div>
            {index < notificationList.length - 1 && <div className={styles.borderLine} />}
          </div>
        ))}
      </div>
      <div className='pagination'>
        {total > pagination.limit && (
          <Pagination
            defaultCurrent={1}
            pageSize={pagination.limit}
            current={pagination.page}
            total={total}
            onChange={(page, pageSize) => {
              setPagination({page, limit: pageSize});
            }}
          />
        )}
      </div>
    </div>
  );
};

export default NotificationComponent;
